import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ४ था" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ४ था' num='04'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे वाग्देवते, मयुरेश्वरी । तुझा वास आमच्या घरी । अज्ञान कैसे राहिल घरी ? ॥ १ ॥
जगात महत्व ज्ञानाला । नच नुसत्या धनाला । धनाच्या जमाखर्चाला । ज्ञान कारक उत्पत्तीला ॥ २ ॥
धन गुरफटवे व्यवहारी । लौकिकात जीव अडकतो भारी । पैशाचे मोजमाप दारोदारी । पैसाच बेजार करी ॥ ३ ॥
मोहमाया सतावे भारी । तिचे साम्राज्य घरोघरी । द्रव्य माध्यमे वावरी । काळजास जखम करी ॥ ४ ॥
अज्ञानाच्या असता राशी । धनिक राहतो उपाशी । जरी अगणित राशी । परी ज्ञानी खातो तुपाशी ॥ ५ ॥
येथे तूप संबंध दर्शवितो । क्रिया प्रक्रिया दर्शवितो । सत्वाचा संबंध दर्शवितो । जो जीवनी आधार ठरतो ॥ ६ ॥
जीवनात तत्वाला महत्व फार । तैसेच सत्वाला महत्व फार । जेथे ह्यांचे प्राधान्य असणार । तेथे दैन्य कैसे टिकणार ? ॥ ७ ॥
दैन्य सैन्य अज्ञानी माणसास । न बाधक ज्ञानी माणसास । नाश त्याचा विनासायास । हेच येते अनुभवास ॥ ८ ॥
हा खेळ चमत्कृतीचा । त्या जगत् निर्मात्याचा । ईश्वरी सत्ता टिकविण्याचा । हाच हेतू असेल त्याचा ॥ ९ ॥
हे तत्व कळले ज्ञान्याला । हेच दृष्टोत्पत्तीला । सारी ईश्वराचीच लीला । म्हणे नको महत्व आपल्याला ॥ १० ॥
गणामहाराज प्रार्थितो । देवा ! लेखन करा म्हणतो । अज्ञान दूर करा सांगतो । ज्ञानी करण्यास सांगतो ॥ ११ ॥
श्री गुरू गजानन लीला । ऐशी येऊ दे लेखनाला । वाचता आनंद मनाला । जो तो सांगेल दुसर्‍याला ॥ १२ ॥
जो करेल ह्याचे पारायण । तो न भटकणार वणवण । पळुन जाईल अज्ञान । मुक्काम करेल ज्ञान ॥ १३ ॥
जेथे सरस्वतीच लिहिणार । गणपती कृपादृष्टी असणार । तेथे काय कमी असणार? । सारे उत्कृष्टच असणार ॥ १४ ॥
गुरूलीला ठरते सार्थक । सुरस कथा एकेक । त्यातील कथा आहे एक । अग्निनिर्मितीची एक ॥ १५ ॥
अशाच एका वैशाख मासी । अक्षय तृतियेच्या दिवशी । चमत्कृती जाहली ऐशी । ती अक्षयच राहिली ॥ १६ ॥
गुरू चमत्कृती नाही करत । सहजगत्या कृत्यात । इच्छा शक्ती एवढी श्रेष्ठ । घडते जे जे विचारात ॥ १७ ॥
ते नाहीत जादुगार । जे नजरबंदी करणार । क्षणभर भासवणार । त्याच्यावर उदर भरणार ॥ १८ ॥
अवलंबतो जादूगार । जादू नि दृष्टीदोषांवर । वाक्‌चातुर्यावर । वातावरण निर्मितीवर ॥ १९ ॥
संतांचे वेगळेच सारे । त्यांचे न ऐसे पसारे । अंतरंगातले दाखवणारे । दृष्टीदोष दूर करणारे ॥ २० ॥
जादूगाराचे ध्येय उदर भरण । प्रेक्षकांचे मनोरंजन । संतांचे ऐसे आचरण । कृतीतून सहज शिकवण ॥ २१ ॥
जादूगाराचे मुद्दामपणे । कृती न घडे सहजपणे । संतांच्या सदाचरणाने । कृती घडते सहजपणे ॥ २२ ॥
तेथे मनाचा निर्मळपणा । मनाचा सात्विकपणा । कनवाळु कृपाळुपणा । पदोपदी जिवंतपणा ॥ २३ ॥
मनोरंजने दुःख न हरे । ते क्षणभरी विसरे । विरंगुळा पळभरे । परी चिंता काळजी न सरे ॥ २४ ॥
संत केवळ नामावर । भक्तांचे दुःख हरणार । जे संतांचे होणार । ते दुःखमुक्त होणार ॥ २५ ॥
जो दुःखमुक्त होतो । तो सदा संतांशीच असतो । मनोरंजन गौण समजतो । नामावरच असतो ॥ २६ ॥
वैशाख अक्षय तृतियेला । महत्व घटदानाला । संतुष्ट करावे ब्राह्मणाला । जेणे संतोष देव पितराला ॥ २७ ॥
नच संतुष्ट देव पितर । नुसत्या घटदानावर । ते संतुष्ट सत्कृत्यांवर । तैसेच सदाचरणावर ॥ २८ ॥
कोणत्या मायबापास । ऐसे ये‌ईल मनास । की आपल्या लेकरांस । संकटांनी घेरावे त्यांस ? ॥ २९ ॥
मायबाप जिवंतपणी । जपे लेकरांस क्षणोक्षणी । सांगे दुष्कृत्ये त्यजुनी । रहावे सत्कृत्यांतुनी ॥ ३० ॥
आपल्या स्वानुभवांवर । अपत्ये जपणार । जेथे ऐसे संस्कार । त्यांचीच स्मृती टिकणार ॥ ३१ ॥
महत्व आहे गुरूलीलेला । महत्व नाही शब्दलीलेला । महत्व आहे घटनेला । अक्षय तृतियेला ॥ ३२ ॥
एका वैशाख मासी । अक्षय तृतियेच्या दिवशी । महाराज सकाळी भक्तांपाशी । बाळगोपाळांपाशी ॥ ३३ ॥
महाराज होते नामजप तंद्रीत । पोरे होती बागडत । महाराजांना नव्हती नडत । जो तो आपापल्या तंद्रीत ॥ ३४ ॥
बाळगोपाळांतही परमेश्वर । जो हे तत्व जाणणार । तो कैसे दूर लोटणार ? । तो त्यांत परमेश्वर पहाणार ॥ ३५ ॥
महाराजांना वेड नामजपाचे । त्यातच ते धुंद व्हायचे । तैसेच वेड चिलीमीचे । परी न व्यसन त्याचे ॥ ३६ ॥
कधी नामजप तंद्रीत । कधी चिलीमीच्या मस्तीत । कुणी नव्हता रोकू शकत । कोण पडणार भानगडीत ? ॥ ३७ ॥
महाराजांची काढण्यात खोड । जाते कार्यात तड । भगवंताची न जोड । काही न वाटणार गोड ॥ ३८ ॥
जो तो उत्सुक जोडण्यात । सर्वदा उपाध्या टाळण्यात । तो नाही पडणार भानगडीत । परी हाताची बोटे सारखी नसतात ॥ ३९ ॥
त्या दिवशी सकाळी । चिलीम नाही पेटलेली । स्वारी ऐसीच बसुन राहिली । चिलीम बाजूस पडलेली ॥ ४० ॥
चिलीमीस हवा वैश्वानर । जेणे चिलीम पेटणार । ते शोधत होते ऐसा नर । जो दे‌ईल त्यांस वैश्वानर ॥ ४१ ॥
पोरांना बोलावले । विस्तवाचे सांगितले । पोरे आपापसात बोले । विस्तव कोठे मिळे ? ॥ ४२ ॥
तेथे जरूर होती अग्नीची । एका तत्वाची । उणीव असता त्यांची । काय स्थिती व्हायची ? ॥ ४३ ॥
देहही पंचमहाभुतयुक्त । हे जाणत होते समर्थ । ते न बसणार अवलंबत । परी वेळेनुसार क्रिया घडतात ॥ ४४ ॥
चिलीमीस अग्नी निमित्त । बोलले समर्थ पोरांप्रत । परी जीवनातले खरे तथ्य । दडविणे न उपयुक्त ॥ ४५ ॥
जगात सत्वच टिकणार । सत्यच टिकणार । जो ह्या तत्वावर जगणार । तोच अमर होणार ॥ ४६ ॥
संतांचा संबंध सत्याशी । नच नुसत्या देहाशी । त्यांची एक एक कृती ऐशी । चमत्कृती झालीशी ॥ ४७ ॥
पोर असतात हुशार फार । कुठे काय मिळणार । हे पटकन कळणार । कुणा न ते पुसणार ॥ ४८ ॥
पोरांच्या डोळ्यासमोर । जानकीराम सोनार । अग्नीविणा न धंदा होणार । म्हणे हाच देईल वैश्वानर ॥ ४९ ॥
सारी पोरे पळाली । सोनाराकडे धावली । माऊली तेथेच राहिली । पोरांकडे बघत राहिली ॥ ५० ॥
पोरे म्हणाली सोनारास । शेठजी आलो तुमच्या घरास । एक विनंती करण्यास । नका दुखवू बाल मनास ॥ ५१ ॥
सोनार म्हणे पोरांस । ऐसे काय बालमनास ? । जेणे सकाळच्या वेळेस । येणे माझ्या घरास ॥ ५२ ॥
पोरे त्यास म्हणाली । आज गुरूमाऊली । चिलीमीवाचून तशीच राहिली । अग्नीविणा चिलीम न पटली ॥ ५३ ॥
माऊलीच्या चिलीमीस । अग्नी हवा त्यास । तो अग्नी मागण्यास । आलो तुमच्या घरास ॥ ५४ ॥
ऐकले सर्व सोनाराने । नकार दिला मानेने । माझ्याकडे मागणे । म्हणजे ते समर्थ नसणे ॥ ५५ ॥
जो योगी म्हणवतो स्वतःस । नागवाच फिरतो रस्त्यास । विधीनिषेध नसे त्यास । पुटपुटतो येईल ते मुखास ॥ ५६ ॥
अक्षय तृतिया दिनास । न द्यावे अग्नीस । काय कळते पोरांस । रागे भरले पोरांस ॥ ५७ ॥
त्या नंग्या पिश्यास । योगलीला अवगत त्यास । कां न निर्मिले अग्नीस ? । धाडले माझ्या घरास ॥ ५८ ॥
त्यानेच करावा वैश्वानर । त्याच्या योगबळावर । रागे भरला पोरांवर । काढून दिले घराबाहेर ॥ ५९ ॥
घालवून दिले परत । म्हणे आता उपाधी रहित । चालीरीती जपण्यात । जन्म घालवला त्यात ॥ ६० ॥
कुणी न सुटे कर्मावाचून । त्याचे फळ मिळाल्यावाचून । जन्मोजन्मीच्या हिशोबातून । कुणी न सुटे त्यातून ॥ ६१ ॥
कर्माचा संबंध प्रसंगाशी । वर्तणूक त्यावेळी जैसी । फळे मिळणार तैसी । हेच तत्व गीतेसी ॥ ६२ ॥
कर्मकृतीची सांगड ऐशी । कोण करणार नाहिशी? । जो ऐशा प्रयत्नासी । ठरे व्यवहार अपयशी ॥ ६३ ॥
जो पडतो ऐशा भानगडीत । संकटे स्वयेच त्याच्या दारात । उपाय सारे थकतात । गुरू चरण धरावे लागतात ॥ ६४ ॥
जानकीरामाचे तसेच झाले । महाराजांना न ओळखले । पोरांना तसेच वाटेला लावले । तेच उपाधीचे कारण झाले ॥ ६५ ॥
महाराजांजवळ परत । हिरमुसली तोंडे परत । जानकीराम न अग्नी देत । तैसेच धाडले परत ॥ ६६ ॥
महाराज न चिडले त्यावर । जशास तसे न वागणार । त्यांचा भरवसा ईश्वरावर । म्हणे काय आज होणार ? ॥ ६७ ॥
बोलले ते बंकटासी । काडी धरावी तू ऐसी । न संबंध पेटीच्या गंधकाशी । अग्नी प्रकट करण्यार्‍या तत्वासी ॥ ६८ ॥
बंकटे तैसे केले । काडीस वरती धरले । भोवताली सारे जमले । टकमक बघू लागले ॥ ६९ ॥
टक लावून पहाता महाराजांनी । आळवले मनोमनी । अंतरात्म्याच्या हाकेनी । तेथेच प्रकटला अग्नी ॥ ७० ॥
योगबळाचे ऐसे झाले । अग्नीस प्रकटणे भाग पडले । सारेच सहज घडले । महाराज समर्थ ठरले ॥ ७१ ॥ 
इकडे जानकीरामास । वृत्त कळले त्यास । आश्चर्य वाटले त्यास । तैसाच थरकाप मनास ॥ ७२ ॥
केला अविचार फार । संकटे आपणा घेरणार । कोण सहाय्य करणार ? । विचारात पडला सोनार ॥ ७३ ॥
दशरथाचे हातून । श्रावणाचे प्राणोत्क्रमण । अनपेक्षित आले घडून । परी दशरथ न सुटला त्यातून ॥ ७४ ॥
येथे आज आपण । महाराजांना चिथावून । बनलो मुद्दाम कारण । आता प्रसंग दारूण ॥ ७५ ॥
अविचाराचा झाला ताप । झाला त्यास मनस्ताप । त्याला जाहला पश्चाताप । बोल निघती आपोआप ॥ ७६ ॥
ते ही सहजपणे घडते । अंतरंग उकलले जाते । कोंडलेले बाहेर पडते । किती कोंडून रहाणार ते ? ॥ ७७ ॥
तेथे न जरूर शिकवणीची । उपदेशाच्या डोसांची । सारी लीला परमेश्वराची । खेळ मांडणार्‍याची ॥ ७८ ॥
वर्‍हाडात अक्षयतृतियेला । महत्व फार चिंचवण्याला । आप्तेष्ट भोजनाला । जमले सोनाराच्या घराला ॥ ७९ ॥
जानकीरामाच्या मनात । जमवावे आप्तेष्ट । चिंचवणे भोजनात । तृप्त होतील आप्तेष्ट ॥ ८० ॥
विचार मनीचा चांगला । जो परवडणार लौकिकाला । जन सुखाच्या सोबतीला । संत दुःखाच्या सोबतीला ॥ ८१ ॥
चिंचवण्यात किडे पडले । निमंत्रिलेले उपाशी राहिले । वचवच करू लागले । फजितीचे कारण झाले ॥ ८२ ॥
उपासमारीचे पातक झाले । सोनाराच्या नशिबी आले । काही सुचेनासे झाले । तोंडचे पाणी पळाले ॥ ८३ ॥
घाबरून गेला क्षणात । किडे दिसती चिंचवण्यात । गोंधळला मनात । काय झाले स्वयंपाकात ? ॥ ८४ ॥
अद्वातद्वा भाषण । निघाले त्याच्या मुखातुन । घाम सुटला दरदरून । काही न सुचे त्यातून ॥ ८५ ॥
सकाळच्या उपाधीस टाळले । मोठ्या आपत्तीचे झाले । क्षणभर सुचेनासे झाले । गोंधळाचे साम्राज्य झाले ॥ ८६ ॥
संत आणि ईश्वर । स्वतःचे अस्तित्व टिकवणार । कैसे खोटे ठरणार ? । चमत्कृतीने अमर होणार ॥ ८७ ॥
संतांचे हृदय कोमल । करती सर्वांस जवळ । दाखवतात योगबळ । संकटे होती विफळ ॥ ८८ ॥
संतांच्या अंगी सामर्थ्य । दिसे त्यांच्या कृतीत । ऐसेच गजानन समर्थ । कळले जानकीरामाप्रत ॥ ८९ ॥
काहींनी सांगितले जानकीरामास । शरण जावे योग्यास । शरण जावे गजाननास । जेणे मुक्त होशील संकटास ॥ ९० ॥
जानकीराम वदला जनांस । अग्नी न दिला चिलीमीस । तेणेच ऐशा प्रसंगास । शरण जाण्या न धाडस ॥ ९१ ॥
जानकीरामास वाटले । गजाननास दुखवले । तेणे अंतरी कोपले । तेणे चिंचवणे नासले ॥ ९२ ॥
कैसे असते अज्ञान ? । समर्थ न सामान्य जन । जेणे क्लुप्ती आचरण । समर्थ तर दयाघन ॥ ९३ ॥
अहो योगी न पडे भानगडीत । जो मुळचाच उपाधीरहित । तो न करणार ऐसे कृत्य । जे ठरेल दुष्कृत्य ॥ ९४ ॥
संत योगी संकटहर्ता । नच संकटकर्ता । तो विघ्नहर्ता । नच विघ्नकर्ता ॥ ९५ ॥
श्रद्धेने तुम्ही पहाता । श्रद्धेने तुम्ही भजता । चरणी ठेवता माथा । तोच तुम्हास त्राता ॥ ९६ ॥
श्रद्धेने मिळते सारे । अश्रद्धेने जाते सारे । श्रद्धेवर अवलंबणारे । तरले आजवर सारे ॥ ९७ ॥
तो शरण गेला महाराजांस । त्याने धरले गुरूचरणास । विनविता झाला त्यांस । कृपा करावी लेकरास ॥ ९८ ॥
पश्चातापे बोलता झाला । चिलीमीस अग्नी न दिला । तेणेच प्रसंग ओढवला । कुणी न सहाय्यभूत ठरला ॥ ९९ ॥
गुरू ऐशी करावी कृती । जेणे टळेल माझी आपत्ती । चिंचवण्यातले किडे दूर होती । जेणे आप्तेष्ट उपाशी न रहाती ॥ १०० ॥
गेलो पुण्य कर्म करण्यास । परी झालो कारण पापास । तुम्हीच तारणार आम्हांस । हेच येते अनुभवास ॥ १०१ ॥
समर्थांचे हृदय द्रवले । त्यांनी त्यास जवळ केले । मी न तुझे चिंचवणे नासले । उगाच तुझे येणे झाले ॥ १०२ ॥
महाराज म्हणे “अरे सोनार! । मी न खोटे बोलणार । तुझे चिंचवणे आहे मधुर । कैसे किडे त्यात असणार ?” ॥ १०३ ॥
सार्‍या जनांनी ऐकले । चिंचवणे पुन्हा पाहिले । चिंचवणे पूर्वव्रत दिसले । एकही कीडा नसलेले ॥ १०४ ॥
सारे जन थक्क झाले । समर्थांचे अधिकार कळले । समर्थांचे चरण धरले । त्यांचेच भक्त झाले ॥ १०५ ॥
आता पुढे काय झाले? । ते सांगणे भाग पडले । ज्येष्ठ महिन्यात ऐसे घडले । चंदू मुकिनाचे भाग्य उदयास आले ॥ १०६ ॥
संत मागतात ज्या घरचे । ते घर ठरते भाग्याचे । ऐसेच झाले चंदू मुकिनाचे । कान्होले मागितले त्या घरचे ॥ १०७ ॥
संतानी त्याच्या घरचे ओळखले । जे जे घरात दडून बसलेले । परी ते न चंदू मुकिना दिसले । तेणे समर्थ अंतर्ज्ञानी ठरले ॥ १०८ ॥
समर्थांची चमत्कृती ऐशी । तिचा संबंध जडतो मनाशी । तैसेच अंतरंगाशी । तेच येते प्रत्ययासी ॥ १०९ ॥
एक वेळ योगी राहील उपाशी । परी भीक न मागणार कुणाशी । हक्काने सांगणार त्यांसी । आलो तुझ्या दाराशी ॥ ११० ॥
तो नाही भीक मागत । केवळ ईश्वराच्या आदेशात । कृती आचरणात । कर्ता करविता भगवंत ॥ १११ ॥
भगवंत कर्ता असल्यावर । चमत्कृतीच घडणार । साधेपणा नसणार । काही विशेष घडणार ॥ ११२ ॥
एका ज्येष्ठ मासात । ते भक्तांच्या समवेत । निःस्सीम भक्त चंदू मुकिन त्यात । महाराज नामजप तंद्रीत ॥ ११३ ॥
कुणी खडीसाखर वाटती । कुणी पंख्याने वारा घालती । कुणी आंबे कापती । कुणी फोडी हातावर ठेवती ॥ ११४ ॥
महाराज म्हणाले मुकीनाला । आंबे नकोत मला । दोन कानवले उतरंडीला । तेच द्यावे खायला ॥ ११५ ॥
मुकीन म्हणाला त्यावर । “कानवले कुठून असणार ?” । एक महिना झाल्यावर । कानवले कुठून टिकणार ? ” ॥ ११६ ॥
समर्थ हसले त्यावर । त्यांची दृष्टी गेली उतरंडीवर । तेथल्या कानवल्यांवर । म्हणे मुकीना “आण तू सत्वर ॥ ११७ ॥
मुकीना नको फसवू मला । नको प्रयोजन ताज्याला । दे शिळेच खावयाला । जे आहेत उतरंडीला” ॥ ११८ ॥
लोक अवघे बोलती । चंदू मुकीनाप्रती । जावे सत्वर घराप्रती । संत न खोटे वदती ॥ ११९ ॥
तो क्षणभर गोंधळला । तसाच तो चालता झाला । गेला आपल्या घराला । कानवले घ्यावयाला ॥ १२० ॥
चंदू आला घराला । कांतेस पुसू लागला । “कानवले उतरंडीला । आहेत का सांग मला ? ” ॥ १२१ ॥
पत्नी बोलती झाली पतीला । “तुम्हीच पहावे उतरंडीला । कान्होल्यास महिना झाला । कैसे रहातील उतरंडीला?” ॥ १२२ ॥
चंदूने उतरंडीस पाहिले । आश्चर्य त्यास वाटले । कानवले होते सुकलेले । उतरंडीस जे पडलेले ॥ १२३ ॥
कानवले पार गेले सुकून । कैसे गुरूस करणार अर्पण? । येथे आहे तत्वज्ञान । वृत्तीवर सारे अवलंबून ॥ १२४ ॥
जे जे आपणास रुचावे । तेच दुसर्‍यास द्यावे । आधी दुसर्‍याचे विचार करावे । मग स्वतःचे हित साधावे ॥ १२५ ॥
दोघे पती पत्नी । हेच विचार मनोमनी । सुकलेले कानवले पाहुनी । अर्पण करण्याचे न धाडस मनी ॥ १२६ ॥
पती पत्नी दोघे आले । उतरंडीचे कानवले । आणते जाहले । परी महाराजांस विनविले ॥ १२७ ॥
नका खाऊ सुकलेले । ताजे करून आणते झाले । समर्थांनी स्मित केले । ताजे शिळे भेद न उरले ॥ १२८ ॥
नुसते न कर्मफळ जाणुनी । तत्व आणले आचरणी । धन्य ती पती पत्नी । कैसे रहाणार कृपेवाचुनी ॥ १२९ ॥
पत्नी योग्यास म्हणते । “ताजे कानवले करते । तेच तुम्हास धाडते । नको विचार शिळ्याचे ते” ॥ १३० ॥
महाराज मुळचेच चमत्कारिक । सुकलेले न बाधक । ताजे शिळे सर्व एकच । कृतीही त्यांची तशीच ॥ १३१ ॥
सुकलेले जे मागितले । तेच कानवले खाल्ले । ताजे शिळे भेद न राहिले । हे पटवते झाले ॥ १३२ ॥
महाराजांच्या हयातीत । अनेक भक्त शरणागत । माधव विप्र त्यात । रहातसे चिंचोलीत ॥ १३३ ॥
संसाराचे चटके सोसत । आयुष्य सरले त्यात । प्रयत्न सारे विफलात । तेणे झाला शरणागत । १३४ ॥
माधव ऐसा विप्र नर । घरदार नि पत्नी सुंदर । संततीयुक्त परीवार । सौख्यात पडत होती भर ॥ १३५ ॥
पुण्य कर्म संपत होते । नविन भरीचे नाव नव्हते । चिंता काळजीचे नाते । माधवाच्या नशिबी होते ॥ १३६ ॥
पत्नीत सदा मन रमलेले । अपत्यांच्या हितात गुंतलेले । पहाता पहाता पुण्य सरले । हे ही त्याला न कळले ॥ १३७ ॥
जितुके शिल्लक असते । तेवढेच उपयुक्त ठरते । प्रयत्नावीण न मिळते । हेच अनुभवास येते ॥ १३८ ॥
पहिले जेव्हा जाते । तेव्हाच दुसरे येते । हेच नेहमी घडते । परी सर्वांना न कळते ॥ १३९ ॥
शिळे न काही टिकत । ताजे न काही उरत । ताजेच शिळे होण्यात । ताजेपणा संपण्यात ॥ १४० ॥
सृष्टीचा चालला ऐसा क्रम । कर्म करण्यास परिश्रम । न करता काहि न उद्यम । न चालणार जीवनक्रम ॥ १४१ ॥
सारे अवलंबे परिश्रमांवर । जो असतो श्रद्धेवर । श्री गुरू आज्ञेवर । त्यासही टाकावी लागे भर ॥ १४२ ॥
ज्या देहात आत्मा असतो । तो चैतन्य मागतो । चेतनामय जीवन करतो । तोच प्रवृत्तीमय राहतो ॥ १४३ ॥
नुसते नसावे देहावरती । भार असावा आत्म्यावरती । जे आत्म्यास संतोषती । तेच भवसागर तरती ॥ १४४ ॥
माधवाचे प्रयत्न देहपुष्टीत । बायको पोरे पोसण्यात । नुसतेच उदर भरण्यात । नच परमेश्वर प्राप्तीत ॥ १४५ ॥
त्याने जाणले देहाला । त्याने न ओळखले आत्म्याला । आतल्या आवाजाला । कैसे ओळखणार परमेश्वराला ? ॥ १४६ ॥
प्रत्येकाच्या नशिबात । लेण देण निश्चित । माधवही होता त्यात । बायको पोरे समवेत ॥ १४७ ॥
वेळेवेळेनुसार । आपत्ती आली माधवावर । ज्यांच्यावर प्रेम जीवापाड । तेच ठरली निराधार ॥ १४८ ॥
पूर्वसंचिताप्रमाणे । यमराजाचे बोलावणे । पत्नी मुलांस क्रमाक्रमाने । तेणे माधवाचे व्यर्थ जीणे ॥ १४९ ॥
माधवाचे वय साठावर । एक एक अवयव क्षीण फार । हमाधुनीचा केला संसार । जीव रमवला प्रपंचावर ॥ १५० ॥
माधव झाला उदास । जीवन झाले भकास । आधार न राहिला त्यास । कंटाळा आला जगण्यास ॥ १५१ ॥
जो निराधार बनतो । आप्तेष्ट त्यास दूर लोटतो । एखादाच पुढे येतो । जो मदतरूप होतो ॥ १५२ ॥
जन सुखाचे सोबती । जन भोजनाचे सोबती । पंचपक्वान्ने झोडती । त्याची वाहवा करती ॥ १५३ ॥
मनमिळाऊ वृत्तीने जो वागतो । दुसर्‍यांस मदतरूप होतो । केवळ धनाचा हिशोब नसतो । त्याला परमेश्वर सहाय्यभूत होतो ॥ १५४ ॥
जन असतात भोजनभाऊ । आपत्तींचा वाटतो बाऊ । एखादाच म्हणे मदतरूप हो‌ऊ । बाकीचे पळून जाऊ ॥ १५५ ॥
आपत्तींना घाबरतात । पळुन जातात क्षणभरात । पळण्याने संकटे नाही टळत । भोगण्याने कमी होतात ॥ १५६ ॥
जीवास प्रारब्ध भोगावे लागते । नियतीचे कोडे न उलगडते । कर्मच प्राधान्य ठरते । तैसे तेणे भोगणे होते ॥ १५७ ॥
आपली मती न चालणार । अंधारातच रमणार । सवय झालेली आजवर । अंधारातच मरणार ॥ १५८ ॥
अंधारच त्याला घेरतो । काळ उभा ठाकतो । प्रयत्न करून कंटाळतो । शेवटी भरवसा ठेवतो ॥ १५९ ॥
संसारात खाव्या लागतात ठोकरा । अनुभवाने सूज्ञ जरा । हाच मार्ग आहे बरा । अन्यथा संकटे येरझारा ॥ १६० ॥
जे वासनामय जगणार । त्यांना मर्म न कळणार । ते वासनेतच गुरफटणार । धोक्यात अडकणार ॥ १६१ ॥
अहो वासनाच त्यांना खाणार । ते काय वासनेला खाणार ? । ते मृगजळाला भुलणार । वासनेच्या आहारी जाणार ॥ १६२ ॥
वासना उपभोगल्याने वाढतात । लोक अग्नीत तेल ओततात । भडका होता प्रयत्न करतात । सारे प्रयत्न विफल होतात ॥ १६३ ॥
नको दोष परमेश्वरास । दोष द्यावे आपल्या कर्मास । जैसे ज्याचे आचरणास । तैसे त्याचे फळ त्यास ॥ १६४ ॥
महाराज सांगतात माधवास । नको हो‌ऊ हताश । नको उतरू उपोषणास । समजून घे मूळ तत्वास ॥ १६५ ॥
माधव उपोषण करतो । नाना तर्‍हे विनवितो । महाराजांजवळ बसतो । परी उसना वैरागी ठरतो ॥ १६६ ॥
जे अंतरंगात नसणार । ते कोठून टिकणार ? । परमार्थात उसने न टिकणार । अंतरंगातले उकलले जाणार ॥ १६७ ॥
परमार्थात घालावे लागते स्वतःचे । निःस्वार्थ नि निःस्पृहतेचे । महत्व शुद्ध अंतरंगाचे । शुद्ध सात्विक विचारांचे ॥ १६८ ॥
जो भरतो ऐशा घागरी । तहानलेला भगवंत त्याचे घरी । जो ऐशी कर्मे आचरी । तोच भवसागर तरी ॥ १६९ ॥
महाराज म्हणे माधवास । तरूणपणी ऐसा वागलास । नुसता देह पोसलास । काय उपयुक्त वृद्धापकाळास ? ॥ १७० ॥
पोसलेस अशाश्वतास । विसरलास शाश्वतास । तैशा आता कर्मफळास । सुटका न तुझ्या जीवास ॥ १७१ ॥
माधवाने हट्ट केले । उपोषण चालू ठेवले । लोकांचे यत्न व्यर्थ ठरले । हट्टासी न सोडले ॥ १७२ ॥
नाही पटले माधवास । बसला सान्निध्यास । आळवे भगवंतास । विनवे महाराजांस ॥ १७३ ॥
रात्रीच्या दोन प्रहरी । शब्द किर्र करी । अंधार पसरला चौफेरी । कुत्री भुंकती वरचेवरी ॥ १७४ ॥
रूप अक्राळ विक्राळ । धारण केले तत्काळ । महाराज ठरले काळ । माधवाच्या गेले जवळ ॥ १७५ ॥
धावून गेले अंगावर । काय करणार माधव त्यावर । कांपू लागला थरथर । बोबडीच वळली सत्वर ॥ १७६ ॥
वाचा क्षणात गेली । छाती धडधडू लागली । गात्रे ढीली झाली । पाचावर धारण बसली ॥ १७७ ॥
माधवाची झाली फजिती । ऐसी पाहुनी त्याची स्थिती । समर्थ सौम्य रूप धारण करती । पहाते झाले माधवाप्रती ॥ १७८ ॥
म्हणे हेच का तुझे धीटपण ? । बोलले त्यास गर्जोन । तू काळाचे भक्ष्य जाण । ह्याची करतो तुज जाण ॥ १७९ ॥
चुणुक तुज दाखवली । तुझी परिक्षा पाहिली । यमलोकी न जागा उरली । पळता भुई थोडी झाली ॥ १८० ॥
माधव सर्व पहाता झाला । तसेच ऐकता झाला । समर्था विनवू लागला । परमार्थ दावा मजला ॥ १८१ ॥
माझ्या पातकांच्या राशी । तेणे भय काळजासी । काळाची भिती मजसी । जाळा पातकांच्या राशी ॥ १८२ ॥
माधवाचे ऐकता भाषण । द्रवले समर्थ अंतःकरण । आयुष्य देतो वाढवून । इच्छा असता मनोमन ॥ १८३ ॥
माधवा ! कर तू नामस्मरण । मुखे म्हणावे नारायण । भितीचे न कारण । तारेल तुज नारायण ॥ १८४ ॥
माधव प्रपंचास कंटाळलेला । मुळातच भयभीत असलेला । यमलोकाला घाबरलेला । पातकांची जाणीव असलेला ॥ १८५ ॥
तो काय आयुष्य मागणार ? । तो मरणच मागणार । माधव म्हणे गुरूवर । वैकुंठास धाडावे सत्वर ॥ १८६ ॥
नको प्रपंच माया । तीच जाळते काया । अशाश्वतात जाते वाया । नको गुरफटणे जीवा ॥ १८७ ॥
आलो तुमच्या जवळ । तुम्ही जाणा सकळ । तुमच्यावरी विश्वास अटळ । नको नको आता भुरळ ॥ १८८ ॥
तुम्हीच करा उद्धार । हाच माझा मनोनिर्धार । तुमच्यावरी विश्वास अटळ । नको नको आता भुरळ ॥ १८९ ॥
माधवाचे संकट टळले । काळाचे भय गेले । यमलोकाचे क्लेश टळले । समर्थे त्यास जवळ केले ॥ १९० ॥
धन्य धन्य तो माधव । ज्याची ऐकावी आर्जव । समर्थांस आली कीव । परमार्थाची केली सोय ॥ १९१ ॥
शेगावकर पुण्यवान । ज्यांना लाभले गजानन । एकेकाचे अंतरंग जाणून । परमार्थी केले रममाण ॥ १९२ ॥
एकदा महाराजांच्या मनात । व्हावी वसंतपूजा शेगावात । आनंद वसंतोत्सवात । चिरकाल टिकतो मनात ॥ १९३ ॥
समर्थ वदले शिष्यांस । करावे वसंतपूजेस । बोलवावे ब्राह्मणांस । करण्या मंत्रजागरणास ॥ १९४ ॥
उत्सवात आनंद वृत्ती । ऐक्य भाव जागृत होती । मिळते चैतन्य नि स्फूर्ती । हास्य जनांच्या मुखावरती ॥ १९५ ॥
जीवनात स्फूर्तीला महत्व फार । स्फूर्तीस कारण सदाचार । तेणेच सत्कृत्य साकार । जीवनात महत्व फार ॥ १९६ ॥
सत्विचार कोणाच्या मनात ? । जो असतो तत्व चिंतनात । तोच राहे सत्विचारांत । तोच राहे सत्कृत्यांत ॥ १९७ ॥
सत्विचार न करावे लागत । ज्याला चिंतनाची सोबत । सत्विचार त्याचे मनात । आपोआप येतात ॥ १९८ ॥
सारे सहज घडते । मुद्दाम न होते तेथे । परोपकारी व्हावेसे वाटते । नुसते म्हणणे न उचित ठरते ॥ १९९ ॥
परोपकार करावा सहजपणे । निर्मळ शुद्ध मनाने । शुद्ध अंतःकरणाने । निःस्वार्थ वृत्तीने ॥ २०० ॥
जीवनात महत्व सहजतेला । महत्व आनंद वृत्तीला । तैसेच ऐक्य भावाला । नि धार्मिक वृत्तीला ॥ २०१ ॥
महाराज म्हणाले भक्तांस । उद्या करावे वसंतपूजेस । जी कधीची ईच्छा मनास । लागा तयारी करण्यास ॥ २०२ ॥
समर्थच ईच्छा करणार । ती कां न पूर्ण होणार ? । समर्थांची ईच्छा काय असणार ? । हीच असणार ॥ २०३ ॥
भक्त म्हणती समर्थांस । उद्या करू वसंतपूजेस । परी वैदिक ब्राह्मण न शेगावास । हीच आडकाठी उत्सवास ॥ २०४ ॥
समर्थ म्हणती त्यावर । तयारी करावी सत्वर । सच्चिदानंद ब्राह्मण धाडणार । वसंतपूजा होणार ॥ २०५ ॥
तयारीची सुरूवात झाली । दक्षिणेची सोय केली । हरभर्‍याची डाळ ओली । कैरीची सोबत झाली ॥ २०६ ॥
थंडगार पन्हे । अत्तरासी न उणे । समर्थ जे जे म्हणे । तैसेच होई वागणे ॥ २०७ ॥
दोन प्रहराच्या समयाला । ब्राह्मण आले शेगावाला । थाटात वसंतपूजा सोहोळा । निर्विघ्न पार पडला ॥ २०८ ॥
खरा धर्म ओळखावा । जीव वासनेत न गुरफटवावा । संबंध सत्कृत्यांशी जडवावा । सत्वृत्तींशी जडवावा ॥ २०९ ॥
सत्वृत्ती ज्याच्या पाठीशी । भगवंत त्याच्या पाठीशी । तो कैसा रहाणार उपाशी ? । निश्चिंती त्याच्या मनासी ॥ २१० ॥
व्यर्थ भटकू नका । मूळचे तत्व सोडू नका । सच्चिदानंद विसरू नका । परावृत्त हो‌ऊ नका ॥ २११ ॥
महाराज कैसे विसरणार ? । ते तंतोतंत आचरणार । श्रद्धेने जीवन जगणार । भगवंत मनोरथ पूर्ण करणार ॥ २१२ ॥
सत्वृत्तिच्या सड्यावर । सत्कर्माची रंगावली काढल्यावर । भगवंत का न तेथे येणार ? । त्यास यावेच लागणार ॥ २१३ ॥
भगवंताने धाडले ब्राह्मणांना । शेगावी वसंतपूजेला । एका आनंदोत्सवाला । चेहेर्‍यावरचे हास्य टिकवायला ॥ २१४ ॥
केवढी सदिच्छा मनाची । पूर्तता व्हावी त्याची । मर्जी असावी भगवंताची । खूण पटते त्याची ॥ २१५ ॥ 
समर्थांची ईच्छा समर्थ ठरली । सारी मंडळी आनंदित झाली । समर्थांची खूण पटली । भक्त मंडळी नतमस्तक झाली ॥ २१६ ॥
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य  चतुर्थो‌ऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
